export const products = [
  {
    id: 1,
    name: 'Virtual Drum Kit',
    img: 'https://res.cloudinary.com/cgilv6/image/upload/v1661270500/camfolio/projects-images/virtual-drum-kit_nny4mv.png',
    link: "https://github.com/gilcamiloa/virtual-drum-kit",
    desc: "Virtual JS drum kit created using react.js, HTML and CSS."
  },
  {
    id: 2,
    name: 'React Portfolio',
    img: "https://res.cloudinary.com/cgilv6/image/upload/v1643858078/camfolio/projects-images/camfolio-skills_ondfoi.png",
    link: "https://github.com/gilcamiloa/camfolio",
    desc: "Camilo's personal project portfolio build using react.js, HTML5, and CSS3.",
  },
  {
    id: 3,
    name: 'theGreenRoom',
    img: 'https://res.cloudinary.com/cgilv6/image/upload/v1643857749/camfolio/projects-images/greenroom-intro_kqvbzp.png',
    link: "http://github.com/gilcamiloa/thegreenroom",
    desc: "This collab simulates up and coming musical acts being able to book performing venues directly with owners avoiding the need for third party management."
  },
  {
    id: 6,
    name:'Leetcode Progress',
    img: "https://res.cloudinary.com/cgilv6/image/upload/v1661282262/camfolio/projects-images/cam-leetcode_jqrwzb.png",
    link: "https://github.com/gilcamiloa/LeetCodePush",
    desc: "View my Leetcode progress on Github! Languages used: JavaScript, Python, C++ among others.",
  },
  {
    id: 4,
    name: 'Minesweeper Clone',
    img: "https://res.cloudinary.com/cgilv6/image/upload/v1672794374/camfolio/projects-images/minesweeper-clone_yvzbsh.png",
    link: "https://minesweeper-clone-app.onrender.com/",
    desc: "This project is a clone of the classic Microsoft Minesweeper, where one is tasked to make it out alive given several hints on potential mine placements."
  },
  {
    id: 5,
    name:'Bust-In',
    img: "https://res.cloudinary.com/cgilv6/image/upload/v1643857748/camfolio/projects-images/bustin-main_s3itsk.png",
    link: "https://github.com/gilcamiloa/thegreenroom",
    desc: "This collab simulates user rentals for private lavatory facilities within a specified city radius built using a built-in map API to select desired preference.",
  },
  // {
  //   id: 6,
  //   name: 'Intro to C++',
  //   img: "https://res.cloudinary.com/cgilv6/image/upload/v1666409440/camfolio/projects-images/intro-to-c_pb6xl8.png",
  //   link: "https://drive.google.com/drive/folders/1E9c0XEPNdt2zjIozjI7LTCibUj_4FJ1i?usp=sharing",
  //   desc: "Intro to C++ course projects created and compiled using CLion IDE."
  // },
  // {
  //   id: 7,
  //   name: 'stackatto',
  //   img: "https://res.cloudinary.com/cgilv6/image/upload/v1643858078/camfolio/projects-images/camfolio-skills_ondfoi.png",
  //   link: "",
  //   desc: "Stackatto, founded in 2022, is a website that allows users to receive anonymous feedback on their musical performance clips from other users. <br>Users can either pay for the service or earn credit by rating others' submissions. The site is broken down into beginner, intermediate, and advanced groups via a complex algorithm allowing a safe space for all users. All users are rated for different qualities."
  // },
];
